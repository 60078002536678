<template>
  <div 
    class="w-12 border-r h-screen fixed left-0 z-10 left-bar_js"
    :class="[ wrapBg, rightBlockBorderColor ]"
  >
    <slot name="discoveryProcess"></slot>

    <div 
      class="w-12 h-12 border-b"
      :class="[ topBlockBorderColor ]"
    ></div>
    <div 
      class="w-12 h-12 border-b"
      :class="[ bottomBlockBorderColor ]"
    ></div>
    <scroll-bar v-if="$route.matched.some(({ name }) => name === 'Search')" />
  </div>
</template>

<script>
import ScrollBar from '@/components/search/ScrollBar.vue';

export default {
  name: 'LeftBar',
  props: {
    wrapBg: {
      type: String,
      default: 'bg-white'
    },
    topBlockBorderColor: {
      type: String,
      default: 'border-black'
    },
    bottomBlockBorderColor: {
      type: String,
      default: ''
    },
    rightBlockBorderColor: {
      type: String,
      default: ''
    },
  },
  components: { ScrollBar },
}
</script>

<style>

</style>