<template>
  <div class="flex relative">
    <left-bar/>
    <div class="content w-full mx-12 main-content">
      <FeatureNav />
      <div class="flex flex-wrap">
          <div class="w-1/5 px-4 pt-64">
            <ul class="border-t border-grey-darker-200">
              <li class="border-b border-dashed">
                <router-link 
                  to="/bucket-admin"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  General
                </router-link>
              </li>
              <!-- <li class="border-b border-dashed">
                <router-link 
                  to="/bucket-admin/configuration"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Bucket Configuration
                </router-link>
              </li> -->
            </ul>
            <ul class="pl-4">
              <!-- <li class="border-b border-dashed">
                <router-link 
                  to="/bucket-admin/configuration/channels"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Channels
                </router-link>
              </li>
              <li class="border-b border-dashed">
                <router-link 
                  to="/bucket-admin/configuration/videos"
                  class="text-base base-font-gray-200 py-3 block bucket-admin-link"
                >
                  Videos
                </router-link>
              </li> -->
            </ul>
          </div>
          <div class="w-4/5">
            <router-view />
          </div>
      </div>
    </div>
    <ProfileNav />
  </div>
</template>

<script>
  import FeatureNav from '@/components/FeatureNav';
  import ProfileNav from '@/components/ProfileNav'
  import LeftBar from '@/components/LeftBar.vue';
  import { mapGetters } from 'vuex'
  import {  bucketsAdminRights } from '@/helpers/constants'

  export default {
    components: {
      FeatureNav, 
      ProfileNav, 
      LeftBar
    },
    data(){
      return{
        isAcceptable: true
      }
    },
    computed: {
      ...mapGetters({
        userAppData: 'general/userAppData'
      })
    },
    mounted(){
      this.isAcceptable = Boolean(bucketsAdminRights[this.userAppData.plan])
      if(process.env.NODE_ENV == 'development') {
        this.isAcceptable = true
      }
      
    }
  }
</script>

<style lang="scss" scoped>
.bucket-admin-link{
  &.router-link-exact-active{
    font-family: 'Ilisarniq Demi', 'sans-serif';
  }
}
</style> 