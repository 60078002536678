var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-l h-screen fixed right-0 transition-all ease-in-out z-10 right-bar_js",class:[ 
    {'w-72 px-8': !_vm.collapsed}, 
    {'w-12': _vm.collapsed},
    {'bg-transparent': _vm.discovery},
    {'bg-white': !_vm.discovery},
    {'onboarding-bg': _vm.onboarding},
    _vm.borderColor
  ]},[(!_vm.onboarding)?[_c('div',{staticClass:"h-12 relative",class:[
        {'border-b border-black': _vm.collapsed},
        _vm.borderColor
      ]},[_c('div',{staticClass:"h-12"}),(_vm.collapsed)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"h-12 w-12 p-1 bg-cover rounded-full -ml-6 -mt-6 relative z-10",style:('background-image: url('+ _vm.user_meta.picture +')')},[_c('div',{staticClass:"w-4 h-4 rounded-full base-bg-checkbox absolute -left-2 top-1/2 avatar-dot z-1 cursor-pointer",on:{"click":function($event){_vm.userMenu = !_vm.userMenu}}})])]):_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"h-12 w-12 p-1 bg-cover rounded-full ml-6 relative z-10",style:('background-image: url('+ _vm.user_meta.picture +')')},[_c('div',{staticClass:"w-4 h-4 rounded-full base-bg-checkbox absolute -left-2 top-1/2 avatar-dot z-1 cursor-pointer",on:{"click":function($event){_vm.userMenu = !_vm.userMenu}}})]),_c('span',{staticClass:"font-monoDemi text-xs pl-4",class:{
            'text-white': _vm.discovery,
            'text-black': !_vm.discovery
          }},[_vm._v(" "+_vm._s(_vm.user_meta.name)+" ")])]),(_vm.userMenu)?_c('div',{staticClass:"nav-user-menu bg-white rounded-xl p-4 absolute zoom-big-enter"},[_c('div',{staticClass:"top flex justify-between border-b pl-2 pr-4 pb-2"},[_c('span',{staticClass:"font-monoDemi text-black text-xs font-medium"},[_vm._v(" "+_vm._s(_vm.user_meta.name)+" ")]),_c('span',{staticClass:"font-grey-darker-500 text-xs cursor-pointer",on:{"click":_vm.logout}},[_vm._v(" Logout ")])]),_c('ul',{staticClass:"px-2 my-2 border-b pb-4",class:[ _vm.borderColor ]},[_c('li',{staticClass:"mt-3"},[_c('router-link',{staticClass:"flex",attrs:{"to":"/settings/account"}},[_c('img',{staticClass:"mr-4",attrs:{"src":require('../assets/icons/user-menu-account.svg'),"alt":""}}),_c('span',{staticClass:"font-sansDemi font-grey-darker-500 text-xs"},[_vm._v(" Account Settings ")])])],1),_c('li',{staticClass:"mt-3"},[_c('router-link',{staticClass:"flex",attrs:{"to":"/settings"}},[_c('img',{staticClass:"mr-4",attrs:{"src":require('../assets/icons/app-settings.svg'),"alt":""}}),_c('span',{staticClass:"font-sansDemi font-grey-darker-500 text-xs"},[_vm._v(" App Settings ")])])],1)]),_c('ul',{staticClass:"px-2 mt-2"},[_c('li',{staticClass:"mt-3"},[_c('router-link',{staticClass:"flex",attrs:{"to":"/buckets"}},[_c('img',{staticClass:"mr-4",attrs:{"src":require('../assets/icons/my-buckets.svg'),"alt":""}}),_c('span',{staticClass:"font-sansDemi font-grey-darker-500 text-xs"},[_vm._v(" My Buckets ")])])],1)])]):_vm._e()]),_c('div',{staticClass:"h-12",class:[
        {'border-b': _vm.collapsed},
        _vm.borderColor
      ]}),_c('ul'),_c('div',{staticClass:"bottomNav absolute bottom-0 w-full left-0"},[(!_vm.collapsed)?_c('ul',{staticClass:"ml-6 px-8 mb-24"},[_c('li',{staticClass:"my-3"},[_c('router-link',{staticClass:"font-monoDemi text-sm",class:{
              'text-white': _vm.discovery,
              'font-grey-darker-500': !_vm.discovery
            },attrs:{"to":"/"}},[_vm._v(" Help ")])],1),_c('li',{staticClass:"my-3"},[_c('a',{attrs:{"href":"mailto:info@raditube.com","target":"_blank"}},[_c('span',{staticClass:"font-monoDemi text-sm cursor-pointer",class:{
              'text-white': _vm.discovery,
              'font-grey-darker-500': !_vm.discovery
            }},[_vm._v(" Feedback ")])])]),_c('li',{staticClass:"my-3"},[_c('a',{staticClass:"font-monoDemi text-sm",class:{
              'text-white': _vm.discovery,
              'font-grey-darker-500': !_vm.discovery
            },attrs:{"href":"https://headwayapp.co/raditube-changelog","target":"_blank"}},[_vm._v(" Changelog ")])])]):_vm._e(),_c('ul',[(_vm.collapsed)?_c('li',{staticClass:"h-12 flex items-center pl-3",class:[
            {'border-t': _vm.collapsed},
            _vm.borderColor
          ]},[_c('span',{staticClass:"block cursor-pointer",class:{
              'text-white': _vm.discovery
            },on:{"click":function($event){return _vm.openNav()}}},[_c('dotsMenu')],1)]):_vm._e(),_c('li',{staticClass:"border-t border-black h-12 flex items-center pl-3",class:[
            {'pl-3': _vm.collapsed},
            {'pl-11': !_vm.collapsed},
            _vm.borderColor
          ]},[_c('span',{staticClass:"block cursor-pointer flex",class:{
              'text-white': _vm.discovery
            },on:{"click":function($event){return _vm.openNav()}}},[_c('div',{staticClass:"w-6 h-6"},[_c('openSide')],1),_c('span',{staticClass:"text-sm ml-4 font-monoDemi",class:{
                'text-white': _vm.discovery,
                'font-grey-darker-500': !_vm.discovery
              }},[_vm._v(" Minimize Menu ")])])])])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }