<template>
  <div 
    :class="[ 
      {'w-72 px-8': !collapsed}, 
      {'w-12': collapsed},
      {'bg-transparent': discovery},
      {'bg-white': !discovery},
      {'onboarding-bg': onboarding},
      borderColor
    ]" 
    class="border-l h-screen fixed right-0 transition-all ease-in-out z-10 right-bar_js">
    <template v-if="!onboarding">
      <div 
        class="h-12 relative"
        :class="[
          {'border-b border-black': collapsed},
          borderColor
        ]"
      >
        <div class="h-12"></div>
        <div v-if="collapsed" class="flex items-center">
          <div 
            class="h-12 w-12 p-1 bg-cover rounded-full -ml-6 -mt-6 relative z-10" 
            :style="'background-image: url('+ user_meta.picture +')'"
          >
            <div 
              @click="userMenu = !userMenu"
              class="w-4 h-4 rounded-full base-bg-checkbox absolute -left-2 top-1/2 avatar-dot z-1 cursor-pointer"
            ></div>
          </div>
        </div>
        <div v-else class="flex items-center">
          <div 
            class="h-12 w-12 p-1 bg-cover rounded-full ml-6 relative z-10" 
            :style="'background-image: url('+ user_meta.picture +')'"
          >
            <div 
              @click="userMenu = !userMenu"
              class="w-4 h-4 rounded-full base-bg-checkbox absolute -left-2 top-1/2 avatar-dot z-1 cursor-pointer"
            ></div>
          </div>
          <span 
            class="font-monoDemi text-xs pl-4"
            :class="{
              'text-white': discovery,
              'text-black': !discovery
            }"
          > 
            {{ user_meta.name }} 
          </span>
        </div>

        <div 
          v-if="userMenu"
          class="nav-user-menu bg-white rounded-xl p-4 absolute zoom-big-enter"
        >
          <div class="top flex justify-between border-b pl-2 pr-4 pb-2">
            <span class="font-monoDemi text-black text-xs font-medium"> {{ user_meta.name }} </span>
            <span @click="logout" class="font-grey-darker-500 text-xs cursor-pointer"> Logout </span>
          </div>
          <ul class="px-2 my-2 border-b pb-4" :class="[ borderColor ]">
            <li class="mt-3">
              <router-link to="/settings/account" class="flex">
                <img :src="require('../assets/icons/user-menu-account.svg')" alt="" class="mr-4">
                <span class="font-sansDemi font-grey-darker-500 text-xs"> Account Settings </span>
              </router-link>
            </li>
            <li class="mt-3">
              <router-link to="/settings" class="flex">
                <img :src="require('../assets/icons/app-settings.svg')" alt="" class="mr-4">
                <span class="font-sansDemi font-grey-darker-500 text-xs"> App Settings </span>
              </router-link>
            </li>
            <!-- <li class="mt-3">
              <router-link to="/settings/billing-plans" class="flex">
                <img :src="require('../assets/icons/subscription.svg')" alt="" class="mr-4">
                <span class="font-sansDemi font-grey-darker-500 text-xs"> Subscription </span>
              </router-link>
            </li> -->
          </ul>
          <ul class="px-2 mt-2">
            <!-- <li class="mt-3">
              <router-link to="/" class="flex">
                <img :src="require('../assets/icons/teams.svg')" alt="" class="mr-4">
                <span class="font-sansDemi font-grey-darker-500 text-xs"> Teams </span>
              </router-link>
            </li> -->
            <li class="mt-3">
              <router-link to="/buckets" class="flex">
                <img :src="require('../assets/icons/my-buckets.svg')" alt="" class="mr-4">
                <span class="font-sansDemi font-grey-darker-500 text-xs"> My Buckets </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>

      <div 
        class="h-12"
        :class="[
          {'border-b': collapsed},
          borderColor
        ]"
      ></div>

      <ul>
        <!-- <li class="border-b h-12 flex items-center pl-3" :class="[ borderColor ]">
          <span class="block"></span>
        </li> -->
        <!-- <li class="border-b h-12 flex items-center pl-3" :class="[ borderColor ]">
          <router-link 
            to="/saved" 
            class="block flex"
            :class="{
              'text-white': discovery
            }"
          >
            <star/>
            <span 
              class="text-base ml-4"
              :class="{
                'text-white': discovery,
                'text-black': !discovery
              }"
            > 
              Saved 
            </span>
          </router-link>
        </li> -->
        <!-- <li class="border-b h-12 flex items-center pl-3">
          <router-link 
            to="/extensions" 
            class="block flex"
            :class="{
              'text-white': discovery,
              'text-black': !discovery
            }"
          >
            <extensions />
            <span 
              class="text-base ml-4"
              :class="{
                'text-white': discovery,
                'text-black': !discovery
              }"
            > 
              Extensions 
            </span>
          </router-link>
        </li> -->
        <!-- <li class="border-b h-12 flex items-center pl-3" :class="[ borderColor ]">
          <router-link 
            to="/settings" 
            class="block flex"
            :class="{
              'text-white': discovery,
              'text-black': !discovery
            }"
          >
            <settingsSelect />
            <span 
              class="text-base ml-4"
              :class="{
                'text-white': discovery,
                'text-black': !discovery
              }"
            > 
              Settings 
            </span>
          </router-link>
        </li> -->
      </ul>

      <div class="bottomNav absolute bottom-0 w-full left-0">
        <ul 
          v-if="!collapsed" 
          class="ml-6 px-8 mb-24"
        >
          <li class="my-3">
            <router-link 
              to="/" 
              class="font-monoDemi text-sm"
              :class="{
                'text-white': discovery,
                'font-grey-darker-500': !discovery
              }"
            > 
              Help 
            </router-link>
          </li>
          <li class="my-3"> 
            <a 
              href="mailto:info@raditube.com" 
              target="_blank">
            <span 
              class="font-monoDemi text-sm cursor-pointer"
              :class="{
                'text-white': discovery,
                'font-grey-darker-500': !discovery
              }"
            >
              Feedback
            </span>
            </a>
          </li>
          <li class="my-3">
            <a 
              href="https://headwayapp.co/raditube-changelog" 
              target="_blank" 
              class="font-monoDemi text-sm"
              :class="{
                'text-white': discovery,
                'font-grey-darker-500': !discovery
              }"
            > 
              Changelog 
            </a>
          </li>
          <!-- <li class="my-3">
            <router-link 
              to="/" 
              class="font-monoDemi text-sm"
              :class="{
                'text-white': discovery,
                'font-grey-darker-500': !discovery
              }"
            > 
              API 
            </router-link>
          </li> -->
        </ul>

        <ul>
          <li 
            v-if="collapsed"
            class="h-12 flex items-center pl-3"
            :class="[
              {'border-t': collapsed},
              borderColor
            ]"
          >
            <span
              @click="openNav()" 
              class="block cursor-pointer"
              :class="{
                'text-white': discovery
              }"
            >
              <dotsMenu />
            </span>
          </li>
          <li 
            class="border-t border-black h-12 flex items-center pl-3"
            :class="[
              {'pl-3': collapsed},
              {'pl-11': !collapsed},
              borderColor
            ]"
          >
            <span 
              @click="openNav()" 
              class="block cursor-pointer flex"
              :class="{
                'text-white': discovery
              }"
            >
              <div class="w-6 h-6">
                <openSide />
              </div>
              <span 
                class="text-sm ml-4 font-monoDemi"
                :class="{
                  'text-white': discovery,
                  'font-grey-darker-500': !discovery
                }"
              > 
                Minimize Menu 
              </span>
            </span>
          </li>
        </ul>
      </div>
    </template>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus } from '@/main'
// import star from '@/components/icons/star'
// import extensions from '@/components/icons/extensions'
// import settingsSelect from '@/components/icons/settings-select'
import dotsMenu from '@/components/icons/dots-menu'
import openSide from '@/components/icons/open-side'

export default {
  name: 'ProfileNav',
  components: {
    // star,
    // extensions,
    // settingsSelect,
    dotsMenu,
    openSide
  },
  props: {
    discovery: {
      type: Boolean,
      default: false
    },
    onboarding: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      collapsed: true,
      userMenu: false
    }
  },
  computed: {
    ...mapGetters({
      avatarImg: 'general/avatarImg',
      user_meta: 'general/user_meta',
    })
  },
  methods: {
    openNav() {
      this.collapsed = !this.collapsed;
      eventBus.$emit('collapseProfileNav', this.collapsed)
    },
    closeMenu(){
      this.collapsed = true
      eventBus.$emit('collapseProfileNav', this.collapsed)
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
  },
  mounted(){
    document.querySelector('.main-content').addEventListener('click', this.closeMenu)
  },
  beforeDestroy(){
    const document = document
    if(document){
      document.querySelector('.main-content').removeEventListener('click', this.closeMenu)
    }
  }

}
</script>

<style lang="scss" scoped>
.avatar-dot{
  transform: translateY(-50%);
}
.nav-user-menu{
  width: 17rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid #F0F0F0;
  right: 100%;
  top: calc(100%);
}


</style>