<template>
  <div :style="{ height: 'calc( 100% - 6rem )' }" class="flex justify-center" ref="scrollBarItemParent">
    <!-- <div 
      v-for="date in histogram"
      :key="date.key"
    >
      {{ $moment(date.key_as_string).format('DD-MM-YYYY') }}
    </div> -->
    <div v-if="lines.length" class="w-4 scrollBarItem">
      <vue-draggable-resizable
        :parent="true"
        :resizable="false"
        @dragstop="dragStop"
        @dragging="dragging"
        :w="16"
        :h="h"
        axis="y"
        class="rounded cursor-pointer"
      >
      </vue-draggable-resizable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus } from '@/main'

export default {
  name: 'Scrollbar',
  data(){
    return {
      h: 10,
      minHeight: 20,
      barHeigth: 0,
      linesHeigth: 0,
      diff: 0,
      itemsPerView: 0,
      itemToscroll: 0
    }
  },
  computed: {
    ...mapGetters({
      histogram: 'search/histogram',
      total_results: 'search/total_results',
      lines: 'search/lines',
    })
  },
  methods: {
    dragging(left, top){
      
      let heightToUSe = this.h < this.minHeight ? this.minHeight : this.h 
      if(top == 0) heightToUSe = 0

      this.itemToscroll = Math.round((top + heightToUSe) / this.diff)
      if(this.itemToscroll <= this.lines.length){
        eventBus.$emit('scrolling', this.itemToscroll)
      }
    },
    dragStop(){
      if(this.itemToscroll > this.lines.length){
        eventBus.$emit('scrollEnd', this.itemToscroll)
      }
    },
    calcItemBarHeigth(lines){
      if(lines > 0){
        const barHeigth = this.$refs.scrollBarItemParent.clientHeight
        const viewHeigth = document.querySelector('.vue-recycle-scroller').clientHeight
        const itemsPerView = viewHeigth / 64
        this.diff =  barHeigth / this.total_results
        
        this.h = Math.round(this.diff * itemsPerView) > this.minHeight ? Math.round(this.diff * itemsPerView) : this.minHeight
      }
    }
  },
  watch:{
    lines(val){
      this.calcItemBarHeigth(val.length)
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollBarItem{
  .vdr{
    border-color: transparent;
    background-color: rgba(255, 196, 196, 0.5);
  }
}
</style>